import * as React from 'react';
import {
  Card,
  CardBody,
  TabStrip,
  TabStripTab,
} from '@progress/kendo-react-layout';
import RFProfileSetup from '../../../RFProfileSetup/Pages/RFProfileSetup';
import { isComponentUpdate } from 'smart-react';
/**
 * Test Details Desktop view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The DesktopView component.
 */
const DesktopView = ({
  selected,
  handleSelect,
  detailsDataModified,
  state,
}) => {
  const [profile, setProfile] = React.useState(state.dataSet);
  React.useEffect(() => {
    setProfile(state.dataSet);
  }, [state]);
  return (
    <>
      <Card>
        <TabStrip
          className='page-tabs'
          selected={selected}
          onSelect={handleSelect}
        >
          <TabStripTab
            title='Profile Setup'
            contentClassName={`tabs-card !k-px-0 !k-py-0 ${
              detailsDataModified === true ? 'tab-modified' : ''
            }`}
          >
            <Card
              className={`tabs-card ${
                detailsDataModified === true ? 'tab-modified' : ''
              }`}
            >
              <CardBody>
                <div className='card-body'>
                  <RFProfileSetup profile={profile} />
                </div>
              </CardBody>
            </Card>
          </TabStripTab>
        </TabStrip>
      </Card>
    </>
  );
};

// Export the memoized component
export default React.memo(DesktopView, isComponentUpdate);
