import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Loader,
  ErrorFallback,
  TextInput,
  InputSwitch,
  isValidate,
  isFromValid,
  DescriptionTextArea,
} from 'smart-react';
import { Card, CardHeader, CardBody } from '@progress/kendo-react-layout';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { buildNotification, GenerateNotification } from 'smart-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  createRFProfile,
  updateRFProfile,
  deleteRFProfile,
} from '../Services/RFProfileService';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Field } from '@progress/kendo-react-form';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../../constants/eventDataTypes';
import { Error } from '@progress/kendo-react-labels';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../Core/Context/AuthContext';
import DesktopLayout from '../Components/Layout/DesktopLayout';
import TenantDropdown from '../../../Core/Components/TenantDropdown/TenantDropdown';
import {
  ValidatePermissions,
  findTenant,
  findTenantById,
} from '../../../../Utils/Auth/ValidatePermissions';
import { getRFProfile } from '../Services/RFProfileService';
import { SplitButton, SplitButtonItem } from '@progress/kendo-react-buttons';
import {
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
  ERROR_MESSAGE,
  DELETE_MESSAGE,
} from '../../../../constants/notificationMessages';
/**
 * Add New RF Profile main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Add New RF Profile component.
 */
const AddRFProfile = () => {
  const navigate = useNavigate();
  const { tenantID, getTokensFromStorage } = useAuth(); // Get tenantID using Auth Context
  /**
   * Used to handle multiple tabs
   */
  const [selected, setSelected] = React.useState(0);
  const [detailsDataModified, setDetailsDataModified] = React.useState(false);
  const { state } = useLocation();
  const [tempState, setTempState] = React.useState(state);
  const [visible, setVisible] = React.useState(false);
  const [isTextArea, setIsTextArea] = React.useState(false);

  const [shouldUpdate, setShouldUpdate] = React.useState(false);
  const [tenant, setTenant] = React.useState(
    findTenantById(state?.profileGroup?.TenantId),
  );
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    TenantId: state?.profileGroup?.TenantId,
    TenantName: state?.profileGroup?.tenant_name,
    SRFProfileId: '',
    SRFProfilesName: '',
    SRFProfilesDetails: '',
    AppURL: '',
    SRFProfileGroupName: state?.profileGroup?.SRFProfileGroupName,
    SRFProfileGroupId: state?.profileGroup?.SRFProfileGroupId,
    Active: true,
  });
  const [editorField, setEditorField] = React.useState({
    name: '',
    value: '',
    label: '',
  });
  const [validateForm, setValidateForm] = React.useState(false);
  const requiredFields = ['SRFProfilesName', 'AppURL'];

  /**
   * Handle Selected Tab
   * @param {*} e
   */
  const handleSelect = (e) => {
    setSelected(e.selected);
    setShouldUpdate(!shouldUpdate);
  };

  /**
   * Get Test Detail from API for edit case
   */
  React.useEffect(() => {
    if (state?.dataSet) {
      const { accessToken } = getTokensFromStorage();

      const { dataSet } = state;
      (async () => {
        setLoading(true);
        const response = await getRFProfile({
          accessToken: accessToken,
          data: dataSet,
          isServiceWorker: false,
        });
        const rfProfileData = response.Payload;
        setTempState({ ...state, dataSet: rfProfileData });
        setFormData(rfProfileData);
        setLoading(false);
      })();
      setFormData({ ...dataSet });
      setTenant(findTenant(dataSet));
      if (ValidatePermissions(dataSet)) {
        setDetailsDataModified(true);
      }
      setShouldUpdate(!shouldUpdate);
    } else {
      setDetailsDataModified(true);
    }
  }, [state]);

  /**
   * on update the tenantID update the formData
   */
  React.useEffect(() => {
    if (!state?.edit) {
      let tempTenant = findTenantById(state?.profileGroup?.TenantId);
      setTenant(tempTenant);
      setFormData((prevState) => ({
        ...prevState,
        TenantId: state?.profileGroup?.TenantId,
        TenantName: tempTenant?.TenantName,
      }));
    }
  }, [tenantID]);

  /**
   * handle Input Change
   * @e object
   */
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  /**
   * onSave
   * submit the rfProfile data to api
   */
  const onSave = async ({ isExit = false, isContinue = false }) => {
    setValidateForm(true);
    if (!isFromValid({ formData, requiredFields })) {
      return;
    }
    setLoading(true);
    let notificationMetaData = {};
    const data = {
      ...formData,
    };
    let response;
    let method;

    if (state?.edit) {
      method = updateRFProfile;
      notificationMetaData = UPDATE_MESSAGE;
    } else {
      method = createRFProfile;
      notificationMetaData = CREATE_MESSAGE;
      setFormData({
        TenantID: state?.profileGroup?.TenantId,
        TenantName: state?.profileGroup?.tenant_name,
        SRFProfileId: '',
        SRFProfilesName: '',
        SRFProfilesDetails: '',
        AppURL: '',
        SRFProfileGroupName: state?.profileGroup?.SRFProfileGroupName,
        SRFProfileGroupId: state?.profileGroup?.SRFProfileGroupId,
        Active: true,
      });
    }

    try {
      setValidateForm(false);
      response = await method({
        data,
        moduleName: 'rfProfile',
        isServiceWorker: false,
      });

      if (response.Message) {
        GenerateNotification(
          buildNotification({
            title: ERROR_MESSAGE?.title,
            description: response.Message,
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      } else if (!response?.IsSuccess) {
        GenerateNotification(
          buildNotification({
            title: ERROR_MESSAGE?.title,
            description: response.StatusDesc,
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      } else {
        GenerateNotification(
          buildNotification(notificationMetaData),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
        // Update tempState to trigger rerender
        setTempState({
          ...state,
          dataSet: response?.Payload,
        });
        if (isContinue) {
          navigate(`/smartrf/profile`, {
            state: {
              edit: true,
              dataSet: response?.Payload,
              profileGroup: state?.profileGroup,
            },
          });
        }
        if (isExit) {
          navigate(`/smartrf/profile-group`, {
            state: {
              edit: true,
              dataSet: state?.profileGroup,
            },
          });
        }
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    } finally {
      setLoading(false);
    }
  };

  /**
   * onExit
   * back to rfProfiles listing screen
   */
  const onExit = () => {
    navigate(`/smartrf/profile-group`, {
      state: {
        edit: true,
        dataSet: state?.profileGroup,
      },
    });
  };

  const handleSaveSplit = (e) => {
    const { itemIndex } = e;
    switch (itemIndex) {
      case 0: {
        onSave({ isExit: true });
        break;
      }
      case 1:
        onSave({ isContinue: true });
      default: {
      }
    }
  };

  /**
   * open delete rf profile dialog
   */
  const DeleteRFProfileDialog = () => {
    setVisible(true);
  };
  /**
   * delete the rf profile
   */
  const onDelete = async () => {
    setVisible(false);
    setLoading(true);
    let response = await deleteRFProfile({
      data: formData,
      isServiceWorker: false,
    });
    try {
      if (response.IsSuccess) {
        GenerateNotification(
          buildNotification(DELETE_MESSAGE),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
        navigate(`/smartrf/profile-group`, {
          state: {
            edit: true,
            dataSet: state?.profileGroup,
          },
        });
      } else {
        GenerateNotification(
          buildNotification(ERROR_MESSAGE),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='container mx-auto'>
        <React.Suspense fallback={<Loader />}>
          {loading && <Loader />}
          {visible && (
            <Dialog
              title={'Please confirm'}
              onClose={() => setVisible(!visible)}
            >
              <p className='dialog-content-msg'>
                Are you sure you want to delete this RF Profile?
              </p>
              <DialogActionsBar>
                <button
                  className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'
                  onClick={() => setVisible(!visible)}
                >
                  No
                </button>
                <button
                  className='k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md'
                  onClick={() => onDelete()}
                >
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

          <div className={`${isTextArea ? '' : 'k-d-none'}`}>
            <DescriptionTextArea
              onChange={handleInputChange}
              label={'Description'}
              renderTypes={!detailsDataModified ? 'ReadOnly' : ''}
              validateForm={validateForm}
              formData={formData}
              setFormData={setFormData}
              detailsDataModified={detailsDataModified}
              isTextArea={isTextArea}
              setIsTextArea={setIsTextArea}
              editorField={editorField}
              setEditorField={setEditorField}
              row={2}
            />
          </div>
          <div
            className={`add-test main-page-section add-page-section ${!isTextArea ? '' : 'k-d-none'}`}
          >
            <Card className='tophead'>
              <CardHeader>
                <div className='action-buttons-container'>
                  <span
                    className='action-icon-wrapper !k-d-block'
                    onClick={() => onExit()}
                  >
                    <FontAwesomeIcon
                      className='k-mr-2 k-pt-3'
                      icon={faArrowLeft}
                    />
                  </span>
                  <div>
                    <p className='display-text k-mb-0'>
                      Profile: {formData?.SRFProfilesName}
                    </p>
                  </div>
                  {state?.edit && detailsDataModified ? (
                    <div className='k-d-flex k-align-items-center k-ml-auto'>
                      <span
                        onClick={() => {
                          DeleteRFProfileDialog();
                        }}
                        className='open-editor-icon k-icon k-font-icon k-i-trash trash-icon detail-trash-icon'
                      ></span>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {formData?.SRFProfilesDetails && (
                  <div className='desciption-label'>
                    <span className='k-d-block k-mb-2'>
                      {formData?.SRFProfilesDetails}
                    </span>
                  </div>
                )}
              </CardHeader>
            </Card>
            <Card className='k-mt-3 detail-card'>
              <CardHeader>
                <h6 className='card-title-secondary'>Details</h6>
              </CardHeader>
              <CardBody className='detail-card detail-card-body'>
                <div>
                  <Form
                    onSubmit={onSave}
                    render={(formRenderProps) => (
                      <div className='card-content'>
                        <div className='k-d-flex single-field-row'>
                          <div className={`field-wrapper`}>
                            <TenantDropdown
                              textField='TenantName'
                              label='Tenant'
                              dataItemKey='TenantId'
                              className='tenant-dropdown k-align-self-end'
                              tenant={tenant}
                              setTenant={setTenant}
                              defaultItem={{
                                TenantName: 'Select Tenant ...',
                                disabled: true,
                              }}
                              editMode={'ReadOnly'}
                              filterable={true}
                              tenantFormId='TenantId'
                              tenantFormName='TenantName'
                              setFormData={setFormData}
                              requiredField={true}
                            />
                          </div>
                        </div>
                        <div className='k-d-flex single-field-row'>
                          <div className={`field-wrapper`}>
                            <Field
                              key={'SRFProfileGroupName'}
                              id={'SRFProfileGroupName'}
                              name={'SRFProfileGroupName'}
                              data={state?.profileGroup?.SRFProfileGroupName}
                              onChange={handleInputChange}
                              label={'Profile Group'}
                              type={'text'}
                              component={TextInput}
                              renderTypes={'ReadOnly'}
                              optional={false}
                            />
                          </div>
                          <div
                            className={`field-wrapper ${isValidate(validateForm, formData?.SRFProfilesName) ? 'field-invalid' : 'field-valid'}`}
                          >
                            <Field
                              key={'SRFProfilesName'}
                              id={'SRFProfilesName'}
                              name={'SRFProfilesName'}
                              data={formData?.SRFProfilesName}
                              onChange={handleInputChange}
                              label={'Profile Name'}
                              type={'text'}
                              component={TextInput}
                              renderTypes={
                                !detailsDataModified ? 'ReadOnly' : ''
                              }
                              optional={false}
                              requiredField={true}
                            />
                            {isValidate(
                              validateForm,
                              formData?.SRFProfilesName,
                            ) && <Error>This field cannot be empty</Error>}
                          </div>
                        </div>
                        <div className='k-d-flex single-field-row'>
                          <div
                            className={`field-wrapper field-wrapper-description field-wrapper-100`}
                          >
                            <DescriptionTextArea
                              onChange={handleInputChange}
                              label={'Description'}
                              renderTypes={
                                !detailsDataModified ? 'ReadOnly' : ''
                              }
                              validateForm={validateForm}
                              formData={formData}
                              setFormData={setFormData}
                              detailsDataModified={detailsDataModified}
                              isTextArea={isTextArea}
                              setIsTextArea={setIsTextArea}
                              editorField={{
                                name: 'SRFProfilesDetails',
                                value: formData?.SRFProfilesDetails,
                                label: 'Description',
                              }}
                              setEditorField={setEditorField}
                              row={2}
                            />
                          </div>
                        </div>

                        <div className='k-d-flex single-field-row k-mb-1'>
                          <div
                            className={`field-wrapper ${isValidate(validateForm, formData?.AppURL) ? 'field-invalid' : 'field-valid'}`}
                          >
                            <Field
                              key={'AppURL'}
                              id={'AppURL'}
                              name={'AppURL'}
                              data={formData?.AppURL}
                              onChange={handleInputChange}
                              label={'App Url'}
                              type={'text'}
                              component={TextInput}
                              renderTypes={
                                !detailsDataModified ? 'ReadOnly' : ''
                              }
                              optional={false}
                              requiredField={true}
                            />
                            {isValidate(validateForm, formData?.AppURL) && (
                              <Error>This field cannot be empty</Error>
                            )}
                          </div>
                        </div>
                        <div className='k-d-flex single-field-row'>
                          <div className={`field-wrapper k-mt-3`}>
                            <Field
                              name='Active'
                              component={InputSwitch}
                              label='Active'
                              onLabel='Yes'
                              offLabel='No'
                              id='Active'
                              checked={formData?.Active}
                              onChange={handleInputChange}
                              disabled={!detailsDataModified}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </CardBody>
            </Card>
            {detailsDataModified && (
              <Card className='k-mt-3'>
                <CardHeader>
                  <div className='k-d-flex single-field-row button-form-wrapper k-action-buttons !k-px-0'>
                    <>
                      <Button
                        icon={'cancel'}
                        onClick={() => onExit()}
                        type='button'
                      >
                        Cancel
                      </Button>
                      <SplitButton
                        text='save'
                        themeColor={'primary'}
                        onButtonClick={() =>
                          onSave({ isExit: false, isContinue: true })
                        }
                        onItemClick={handleSaveSplit}
                      >
                        <SplitButtonItem text='Save & Close' />
                      </SplitButton>
                    </>
                  </div>
                </CardHeader>
              </Card>
            )}
            {tempState?.edit && (
              <Card className='k-mt-3 detail-grid-section'>
                <CardBody className='!k-px-0'>
                  <DesktopLayout
                    handleSelect={handleSelect}
                    selected={selected}
                    detailsDataModified={detailsDataModified}
                    state={tempState}
                    shouldUpdate={shouldUpdate}
                    setShouldUpdate={setShouldUpdate}
                  />
                </CardBody>
              </Card>
            )}
          </div>
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default AddRFProfile;
